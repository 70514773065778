import { useEffect, useState } from "react";
import Template from "../utils/Template";
import {
  HandleLogout,
  amountCommasSparated,
  decodeData,
  timeFormat,
} from "../utils/Helper";
import {
  getMethodService,
  postMethodService,
  putMethodService,
} from "../services";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  THREED_SETTING_API,
  THREED_SETTING_EDIT_API,
  TWOD_SETTING_API,
  TWOD_SETTING_EDIT_API,
  TWO_THREE_SETTING_API,
  TWO_THREE_SETTING_EDIT_API,
} from "../services/constants";
import { stateObjType } from "../types";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import Toggle from "../utils/Toggle";
import ModalBox from "../utils/ModalBox";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { momentTimeFormat, momentDateFormat,betType } from '../services/helper';

const Setting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user_info } = useSelector((state: any) => state.userInfo);
  const [twoSetting, setTwoSetting] = useState<stateObjType>([]);
  const [threeSetting, setThreeSetting] = useState<stateObjType>([]);
  const navigate = useNavigate();
  const [twodRefreshKey, setTwodRefreshKey] = useState(0);
  const [threedRefreshKey, setThreedRefreshKey] = useState(0);
  const [showTwodSettingEditModal, setShowTwodSettingEditModal] =
    useState(false);
  const [showThreedSettingEditModal, setShowThreedSettingEditModal] =
    useState(false);
  const [openingTime, setOpeningTime] = useState<any>("");
  const [closingTime, setClosingTime] = useState<any>("");
  const [editData, setEditData] = useState<any>(null);

  const fetchTwoSetting = () =>
    getMethodService(`${TWOD_SETTING_API}?sortDirection=asc`);
  const twoSettingQuery = useQuery({
    queryKey: ["twoSetting", twodRefreshKey],
    queryFn: fetchTwoSetting,
    refetchOnWindowFocus: false,
  });
  const fetchThreeSetting = () =>
    getMethodService(`${THREED_SETTING_API}?sortDirection=asc`);
  const threeSettingQuery = useQuery({
    queryKey: ["threeSetting", threedRefreshKey],
    queryFn: fetchThreeSetting,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (twoSettingQuery) {
      setIsLoading(twoSettingQuery.isLoading);
      if (
        twoSettingQuery?.data === "Unauthenticated." ||
        twoSettingQuery?.data === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
      ) {
        HandleLogout(navigate);
      }
      if (twoSettingQuery?.data?.status === "success") {
        setTwoSetting(twoSettingQuery?.data?.data);
      }
    }
  }, [twoSettingQuery]);
  console.log(user_info);

  useEffect(() => {
    if (threeSettingQuery) {
      setIsLoading(threeSettingQuery.isLoading);
      if (
        threeSettingQuery?.data === "Unauthenticated." ||
        threeSettingQuery?.data === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
      ) {
        HandleLogout(navigate);
      }
      if (threeSettingQuery?.data?.status === "success") {
        setThreeSetting(threeSettingQuery?.data?.data);
      }
    }
  }, [threeSettingQuery]);

  const twoStatusToggleHandler = (status: any, id: number) => {
    postMethodService(
      TWOD_SETTING_EDIT_API(id),
      { status: `${status ? 0 : 1}` },
      true
    ).then((res) => {
      setIsLoading(false);
      if (
        res === "Unauthenticated." ||
        res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
      ) {
        HandleLogout(navigate);
      }
      if (res?.status === "success") {
        setTwodRefreshKey((prev) => prev + 1);
      }
    });
  };

  const threeStatusToggleHandler = (status: any, id: number) => {
    postMethodService(
      THREED_SETTING_EDIT_API(id),
      { status: `${status ? 0 : 1}` },
      true
    ).then((res) => {
      setIsLoading(false);
      if (
        res === "Unauthenticated." ||
        res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
      ) {
        HandleLogout(navigate);
      }
      if (res?.status === "success") {
        setThreedRefreshKey((prev) => prev + 1);
      }
    });
  };

  useEffect(() => {
    setOpeningTime(editData?.opening_time);
    setClosingTime(editData?.closing_time);
  }, [editData]);

  const twodEditSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    const data = {
      opening_time: openingTime,
      closing_time: closingTime,
    };
    if (editData) {
      setIsLoading(true);
      postMethodService(TWOD_SETTING_EDIT_API(editData?.id), data, true).then(
        (res) => {
          setIsLoading(false);
          if (
            res === "Unauthenticated." ||
            res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
          ) {
            HandleLogout(navigate);
          }
          if (res?.status === "success") {
            setShowTwodSettingEditModal(false);
            setTwodRefreshKey((prevKey) => prevKey + 1);
          }
        }
      );
    }
  };

  const threedEditSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    const data = {
      opening_time: openingTime.includes("T")
        ? `${openingTime?.split("T")[0]} ${timeFormat(openingTime)}`
        : `${openingTime?.split(" ")[0]} ${timeFormat(openingTime)}`,
      closing_time: closingTime.includes("T")
        ? `${closingTime?.split("T")[0]} ${timeFormat(closingTime)}`
        : `${closingTime?.split(" ")[0]} ${timeFormat(closingTime)}`,
    };

    if (editData) {
      setIsLoading(true);
      postMethodService(THREED_SETTING_EDIT_API(editData?.id), data, true).then(
        (res) => {
          setIsLoading(false);
          if (
            res === "Unauthenticated." ||
            res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
          ) {
            HandleLogout(navigate);
          }
          if (res?.status === "success") {
            setShowThreedSettingEditModal(false);
            setThreedRefreshKey((prevKey) => prevKey + 1);
          }
        }
      );
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-header">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-3">
              <h4 className="card-title">2D Close Time</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <TableScroll>
                <div>
                  <table className="table text-white w-100 table-bordered table-striped">
                    <thead>
                      <tr style={{ backgroundColor: "rgb(70 70 227)" }}>
                        <th className="p-2 text-center text-light">No</th>
                        <th className="p-2 text-center text-light">
                          Opening Time
                        </th>
                        <th className="p-2 text-center text-light">
                          Closing Time
                        </th>
                        <th className="p-2 text-center text-light">
                          Lottery Time
                        </th>
                        <th className="p-2 text-center text-light">Type</th>
                        <th className="p-2 text-center text-light">
                          Close/Open
                        </th>
                        {user_info && user_info.role_id !== 3 ? (
                          <th className="p-2 text-center text-light">Action</th>
                        ) : null}
                        <th className="p-2 text-center text-light">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && <TableLoading col={8} />}
                      {twoSetting.length > 0 ? (
                        twoSetting.map((setting: stateObjType, i: number) => (
                          <tr style={{ verticalAlign: "middle" }} key={i}>
                            <td className="p-2 text-center text-dark">
                              {i + 1}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {momentTimeFormat(setting?.opening_time)}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {momentTimeFormat(setting?.closing_time)}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {setting?.type === "MM" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                      12:01 PM
                                    </span>
                                  )}
                                  {setting?.time === "2" && (
                                    <span className="badge bg-danger">
                                      04:30 PM
                                    </span>
                                  )}
                                </>
                              )}
                              {setting?.type === "GL" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                      09:30 AM
                                    </span>
                                  )}
                                  {setting?.time === "2" && (
                                    <span className="badge bg-danger">
                                      12:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "3" && (
                                    <span className="badge bg-primary">
                                      02:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "4" && (
                                    <span className="badge bg-warning">
                                      04:30 PM
                                    </span>
                                  )}
                                  {setting?.time === "5" && (
                                    <span className="badge bg-success">
                                      08:00 PM
                                    </span>
                                  )}
                                </>
                              )}
                              {setting?.type === "DB" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                      11:00 AM
                                    </span>
                                  )}
                                  {setting?.time === "2" && (
                                    <span className="badge bg-danger">
                                      01:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "3" && (
                                    <span className="badge bg-primary">
                                      03:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "4" && (
                                    <span className="badge bg-warning">
                                      05:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "5" && (
                                    <span className="badge bg-secondary">
                                      07:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "6" && (
                                    <span className="badge bg-success">
                                      09:00 PM
                                    </span>
                                  )}
                                </>
                              )}
                              {setting?.type === "ML" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                      10:00 AM
                                    </span>
                                  )}
                                  {setting?.time === "2" && (
                                    <span className="badge bg-danger">
                                      12:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "3" && (
                                    <span className="badge bg-primary">
                                      02:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "4" && (
                                    <span className="badge bg-warning">
                                      04:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "5" && (
                                    <span className="badge bg-secondary">
                                      06:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "6" && (
                                    <span className="badge bg-success">
                                      08:00 PM
                                    </span>
                                  )}
                                </>
                              )}
                              {setting?.type === "BTC" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                      09:30 AM
                                    </span>
                                  )}
                                  {setting?.time === "2" && (
                                    <span className="badge bg-danger">
                                      12:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "3" && (
                                    <span className="badge bg-primary">
                                      02:00 PM
                                    </span>
                                  )}
                                  {setting?.time === "4" && (
                                    <span className="badge bg-warning">
                                      04:30 PM
                                    </span>
                                  )}
                                  {setting?.time === "5" && (
                                    <span className="badge bg-success">
                                      08:00 PM
                                    </span>
                                  )}
                                </>
                              )}
                            </td>

                            <td className="p-2 text-center text-dark">
                              {betType(setting?.type)}
                            </td>
                            <td className="p-2 text-center text-dark">
                              <Toggle
                                id={setting.id}
                                status={setting?.status}
                                forId={`twoToggle${setting?.id}`}
                                event={twoStatusToggleHandler}
                              />
                            </td>
                            {user_info && user_info.role_id !== 3 ? (
                              <td className="p-2 text-center text-dark">
                                <button
                                  className="btn btn-outline-success py-1"
                                  onClick={() => {
                                    setShowTwodSettingEditModal(true);
                                    setEditData(setting);
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                            ) : null}

                            <td className="p-2 text-center text-dark">
                              {momentDateFormat(setting?.updated_at)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={8}>
                            No found data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </TableScroll>
            </div>
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-header">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-3">
              <h4 className="card-title">3D Close Time</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <TableScroll>
                <div>
                  <table className="table text-white w-100 table-bordered table-striped">
                    <thead>
                      <tr style={{ backgroundColor: "rgb(70 70 227)" }}>
                        <th className="p-2 text-center text-light">No</th>
                        <th className="p-2 text-center text-light">
                          Opening Time
                        </th>
                        <th className="p-2 text-center text-light">
                          Closing Time
                        </th>
                        <th className="p-2 text-center text-light">
                          Lottery Time
                        </th>
                        <th className="p-2 text-center text-light">Type</th>
                        <th className="p-2 text-center text-light">
                          Close/Open
                        </th>
                        {/* <th className="p-2 text-center text-light">Action</th> */}
                        <th className="p-2 text-center text-light">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && <TableLoading col={8} />}
                      {threeSetting.length > 0 ? (
                        threeSetting.map((setting: stateObjType, i: number) => (
                          <tr style={{ verticalAlign: "middle" }} key={i}>
                            <td className="p-2 text-center text-dark">
                              {i + 1}
                            </td>
                            <td className="p-2 text-center text-dark">
                              { momentDateFormat(setting?.opening_time)}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {momentDateFormat(setting?.closing_time)}
                            </td>

                            <td className="p-2 text-center text-dark">
                              {setting?.type === "MM" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                      ၁၆ ရက်
                                    </span>
                                  )}
                                  {setting?.time === "2" && (
                                    <span className="badge bg-info">၁ ရက်</span>
                                  )}
                                </>
                              )}
                              {setting?.type === "DB" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                     တနင်္ဂနွေ
                                    </span>
                                  )}
                                </>
                              )}
                              {/* {setting?.type === "BTC" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                      တနင်္ဂနွေ
                                    </span>
                                  )}
                                </>
                              )} */}
                              {setting?.type === "GL" && (
                                <>
                                  {setting?.time === "1" && (
                                    <span className="badge bg-info">
                                      တနင်္ဂနွေ
                                    </span>
                                  )}
                                </>
                              )}
                            </td>
                            <td className="p-2 text-center text-dark">
                              {betType(setting?.type)}
                            </td>
                            <td className="p-2 text-center text-dark">
                              <Toggle
                                id={setting?.id}
                                status={setting?.status}
                                forId={`threeToggle${setting?.id}`}
                                event={threeStatusToggleHandler}
                              />
                            </td>
                            {/* <td className="p-2 text-center text-dark">
                              <button
                                className="btn btn-outline-success py-1"
                                onClick={() => {
                                  setShowThreedSettingEditModal(true);
                                  setEditData(setting);
                                }}
                              >
                                Edit
                              </button>
                            </td> */}
                            <td className="p-2 text-center text-dark">
                              {setting?.updated_at}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={8}>
                            No found data
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </TableScroll>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {showTwodSettingEditModal && (
        <ModalBox
          title="Update Time"
          onClose={() => setShowTwodSettingEditModal(false)}
          onSubmit={twodEditSubmitHandler}
          isLoading={isLoading}
        >
          <Form>
            <FormGroup>
              <Label for="amount">Opening Time</Label>
              <Input
                type="time"
                value={openingTime}
                name="time"
                step={1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setOpeningTime(e.target.value)
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="amount">Closing Time</Label>
              <Input
                type="time"
                value={closingTime}
                name="time"
                step={1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setClosingTime(e.target.value)
                }
              />
            </FormGroup>
          </Form>
        </ModalBox>
      )}
      {showThreedSettingEditModal && (
        <ModalBox
          title="Update Time"
          onClose={() => setShowThreedSettingEditModal(false)}
          onSubmit={threedEditSubmitHandler}
          isLoading={isLoading}
        >
          <Form>
            <FormGroup>
              <Label for="amount">Opening Time</Label>
              <Input
                type="datetime-local"
                step={1}
                value={openingTime}
                name="time"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setOpeningTime(e.target.value)
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="amount">Closing Time</Label>
              <Input
                type="datetime-local"
                step={1}
                value={closingTime}
                name="time"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setClosingTime(e.target.value)
                }
              />
            </FormGroup>
          </Form>
        </ModalBox>
      )}
    </>
  );
};

export default Setting;
